import React from 'react'
import { useState } from 'react';
import { async } from 'q';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

function SpecialGuest({ name }) {
    const [masterpsw, setMasterpsw] = useState(null)
    const history=useHistory()

    // const submitDetails = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const res = await axios.post('http://www.lohanasummit.i-tax.in/api/PosterApi/CreateRSVP',
    //             {
    //                 // Id: categoryid,
    //                 Name: name,
    //                 Attend: attend,
    //                 Noofguest: totalGuest,
    //                 Meal: meal,
    //             })
    //         console.log(res, "Ankit")
    //         if (res.data.Success == true) {
    //             notification.success({
    //                 message: 'Thank you !!!',
    //                 placement: 'bottomRight', // You can adjust the placement
    //                 duration: 1, // Adjust the duration as needed
    //             });
    //         }
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }
    // }
    const handlespecialnext = () => {
        if (masterpsw === "3012") {
            history.push('/specialguest')
        }
        else {
            notification.error({
                message: 'Something want to Wrong !!',
                placement: 'bottomRight', // You can adjust the placement
                duration: 1, // Adjust the duration as needed
            });
        }
    }
    return (
        <section className=" " id="rsvp">
            <div
                className="wpo-contact-section-inner"
            >
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="wpo-contact-form-area">
                            <div className='m-1'>
                                <div>
                                    <label>Password :</label>
                                </div>
                                <div >
                                    <input type='password' className='form-control' autoComplete="off" placeholder='Enter Master Password' value={masterpsw} onChange={(event)=>{setMasterpsw(event.target.value)}} />
                                </div>
                            </div>
                            {/* <div className='m-1'>
                                <label>Number Of Guests :</label>
                                <select className="form-control" value={totalGuest} onChange={(event) => setTotalGuest(event.target.value)}>
                                    <option selected>
                                        Number Of Guests
                                    </option>
                                    <option value="1">01</option>
                                    <option value="2">02</option>
                                    <option value="3">03</option>
                                    <option value="4">04</option>
                                    <option value="5">05</option>
                                    <option value="6">06</option>
                                    <option value="7">07</option>
                                    <option value="8">08</option>
                                    <option value="9">09</option>
                                    <option value="10">10</option>
                                </select>
                            </div> */}
                            <div className="submit-area text-center">
                                <button type="submit" className="theme-btn" onClick={handlespecialnext}>
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SpecialGuest