import React, { useEffect } from 'react'
import { useState } from 'react';
import { async } from 'q';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { notification } from 'antd';
import { FaRegCirclePlay } from "react-icons/fa6";
import Countdown from "./Countdown";
import SpecialGuest from './SpecialGuest';

// import jdimg from "../components/JD IMGES/images/slider/hero4-flower.png"
import jdimg from "./JD IMGES/images/slider/hero4-flower.png"
import EngagementImg from "../components/JD IMGES/Engagement.png"


import VideoBannerr from "../components/JD IMGES/video/video.mp4"
import Kinjal from "../components/JD IMGES/450X450 KINJAL.png"
import Jaydeep from "../components/JD IMGES/450X450 JAYDEEP.png"

import CountdownHome from "../components/JD IMGES/800x897.jpg"

import VideoBanner from "../components/JD IMGES/1660x540.jpg"
import JKImage from "../components/JD IMGES/J AND K.jpg"
import MandapMurhat from "../components/JD IMGES/Mandap Muhrat 1.png"
import GaneshSthapna from "../components/JD IMGES/Ganesh Sathapna & Grah Shanti (1).jpg"
import BhojanSamaroh from "../components/JD IMGES/lunch.png"
import Reception from "../components/JD IMGES/reception.png"
import Haldirasam from "../components/JD IMGES/haldi.png"
import PujSaheb from "../components/JD IMGES/blog-4-2.png"
import Sangeet from "../components/JD IMGES/sangit.png"
import WeddingReception from "../components/JD IMGES/reception.png"
import Wedlock from "../components/JD IMGES/wed-lock.png"
import Barat from "../components/JD IMGES/barat.png"
import SaheraBandhi from "../components/JD IMGES/Sahera Bandhi.jpg"
import Ghadiyun from "../components/JD IMGES/ghadiyun.png"

import GalleryCenter from "../components/JD IMGES/717X1069.jpg"
// import Gallery1 from "../components/JD IMGES/552X398 1.jpg"
import Gallery1 from "../components/JD IMGES/jdmix.png"
import Gallery2 from "../components/JD IMGES/552X398 2.jpg"
import Gallery3 from "../components/JD IMGES/552X398 3.jpg"
import Gallery4 from "../components/JD IMGES/552X398 4.jpg"


import when1 from "../components/JD IMGES/408X609 JK7.jpg"
import when2 from "../components/JD IMGES/Group 441.png"

// function NewData(props) {
//     // const { fetchData, getCategoryData } = props
//     return (
//         <Modal
//             {...props}
//             size="xl"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//             backdrop="static"
//         >
//             <SpecialGuest />
//         </Modal>
//         // console.log("Ankit")
//     );
// }
function SpecialGuestName(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Special Guest
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SpecialGuest />
            </Modal.Body>
        </Modal>
    );
}

const Home = () => {
    const [name, setName] = useState("")
    const [attend, setAttend] = useState(true)
    const [totalGuest, setTotalGuest] = useState("0")
    const [meal, setMeal] = useState("VEG")
    const [specialguestShow, setSpecialGuestShow] = React.useState(false);
    const URL = process.env.REACT_APP_API_URL;

    const submitDetails = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post('http://www.lohanasummit.i-tax.in/api/PosterApi/CreateRSVP',
                {
                    // Id: categoryid,
                    Name: name,
                    Attend: attend,
                    Noofguest: totalGuest,
                    Meal: meal,
                })
            if (res.data.Success == true) {
                setName("")
                setAttend(true)
                setTotalGuest("0")
                setMeal("VEG")
                notification.success({
                    message: 'Thank you !!!',
                    placement: 'bottomRight', // You can adjust the placement
                    duration: 1, // Adjust the duration as needed
                });
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {
    //     if (meal == 'More') {
    //        setSpecialGuestShow(true);
    //     }

    // }, [meal])
    const handleMealChange = (event) => {
        const selectedMeal = event.target.value;
        // Update state
        setMeal(selectedMeal);

        // Check if "More" is selected and call your function
        // if (selectedMeal === "More") {
        //     NewData();
        // }
    };



    const handleOthers = () => {
        setSpecialGuestShow(true);
    }
    return (
        <div>
            {/* start preloader */}
            {/* <div className="preloader">
                <div className="vertical-centered-box">
                    <div className="content">
                        <div className="loader-circle" />
                        <div className="loader-line-mask">
                            <div className="loader-line" />
                        </div> */}
            {/* <img src="assets/images/preloader.gif" alt="" /> */}
            {/* </div>
                </div>
            </div> */}
            {/* end preloader */}
            <section
                className="static-hero-s3"
                style={{
                    background:
                        "url(./assets/images/slider/hero-bg22.png) no-repeat center center"
                }}
            >
                <div className="hero-container">
                    <div className="hero-inner">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-6 col-12">
                                    <div className="wpo-static-hero-inner">
                                        <div
                                            className="slide-title  zoomIn"
                                            data-wow-duration="1500ms"
                                        >
                                            <h2>
                                                Save <span>the</span> Date
                                            </h2>
                                            <div className="shape">
                                                <img src={jdimg} alt="" />
                                            </div>
                                        </div>
                                        <div
                                            className="slide-sub-title  fadeInUp"
                                            data-wow-duration="1500ms"
                                        >
                                            <h3>Kinjal &amp; Jaydeep</h3>
                                        </div>
                                        <div
                                            data-swiper-parallax={400}
                                            className="slide-text  fadeInUp"
                                            data-wow-duration="1600ms"
                                        >
                                            <p>We Are Getting Married On December 30,2023</p>
                                        </div>
                                        <div className="clearfix" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="static-hero-right">
                    <div className="static-hero-img">
                        <div className="static-hero-img-inner">
                            {/* <img className="zoom" src="assets/images/slider/hero-3.jpg" alt="" /> */}
                            <img className="zoom" src={CountdownHome} alt=""></img>
                        </div>
                    </div>
                    {/* <div className="wpo-wedding-date">
        <div className="clock-grids">
          <div id="clock" />
        </div>
      </div> */}
                    <div className="wpo-wedding-date">
                        <div className="clock-grids">
                            <div id="clock">
                                <Countdown />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wpo-couple-section-s4 section-padding mt-5" id="couple">
                <div className="container-fluid">
                    <div className="couple-area clearfix">
                        <div className="row align-items-center">
                            <div className="col col-xl-3 col-lg-4 col-12">
                                <div
                                    className="text-grid  fadeInLeftSlow"
                                    data-wow-duration="800ms"
                                >
                                    <h3>Kinjal Goswami</h3>
                                    {/* <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                                        orci auctor vitae nisl. fringilla pellesque amet tempus.
                                    </p> */}
                                    {/* <div className="social">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <i className="ti-facebook" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="ti-twitter-alt" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="ti-instagram" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col col-xl-6 col-lg-4 col-12">
                                <div className="middle-couple-pic-wrap">
                                    <div
                                        className="middle-couple-pic  fadeInUp"
                                        data-wow-duration="1000ms"
                                    >
                                        <div className="middle-couple-pic-inner">
                                            <img src={Kinjal} alt="" />
                                        </div>
                                    </div>
                                    <div
                                        className="middle-couple-pic  fadeInUp"
                                        data-wow-duration="1000ms"
                                    >
                                        <div className="middle-couple-pic-inner">
                                            <img src={Jaydeep} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-3 col-lg-4 col-12">
                                <div
                                    className="text-grid  fadeInRightSlow"
                                    data-wow-duration="800ms"
                                >
                                    <h3>Jaydeep <br></br> Rajai</h3>
                                    {/* <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                                        orci auctor vitae nisl. fringilla pellesque amet tempus.
                                    </p> */}
                                    {/* <div className="social">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <i className="ti-facebook" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="ti-twitter-alt" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="ti-instagram" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wpo-video-section section-padding">
                <h2 className="hidden">some</h2>
                <div className="container-fluid">
                    <div className="video-wrap">
                        {/* <video  autoplay muted>
                            <source src={VideoBannerr} type="video/mp4" />
                        </video> */}
                        <img src={VideoBanner} alt="" style={{ width: "100%" }} />
                        <a
                            href={VideoBannerr}
                            className="video-btn"
                            data-type="iframe"
                        >
                            {/* <i className="fi flaticon-play" /> */}
                            <FaRegCirclePlay size={80} color='white' />
                        </a>
                    </div>
                </div>
            </section>
            <section className="wpo-event-section-s3 section-padding" id="event">
                <div className="container">
                    <div className="wpo-section-title">
                        <span>Our Wedding</span>
                        <h2>When &amp; Where</h2>
                    </div>
                    {/* <div className="wpo-section-title">
                        <h2 style={{ borderBottom: "4px solid burlywood" }}>At Surat</h2>
                    </div> */}
                    {/* <div className="wpo-event-wrap">
                        <div className="row">
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1000ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={MandapMurhat} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Mandap <br></br>Muhrat</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 28/12/2023 Thursday</li>
                                            <li> Morning 10:00 A.M.</li>
                                            <li>25/A, Navdurga Society, B/S Maharana Paratap Garden, Nana Varacha, Surat.</li>
                                            <li> ( Smt. Priti J. Rajai )</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1200ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={GaneshSthapna} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Ganesh Sathapna & Grah Shanti</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 28/12/2023 Thursday</li>
                                            <li> Morning 11:15 A.M.</li>
                                            <li>25/A, Navdurga Society, B/S Maharana Paratap Garden, Nana Varacha, Surat.</li>
                                            <li>( Smt. Shital S. Rajai )</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1400ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={BhojanSamaroh} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Lunch</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 28/12/2023 Thursday</li>
                                            <li> Afternoon 12:35 P.M.</li>
                                            <li>25/A, Navdurga Society, B/S Maharana Paratap Garden, Nana Varacha, Surat.</li>
                                            <li> ( Mohit Rajai & Maitrik rajai )</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1400ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={Reception} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Reception</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 01/01/2024 Thursday</li>
                                            <li> 07:00 P.M. to 10:00 P.M. </li>
                                            <li>Rio Carnival</li>
                                            <li>Near Sarthana Police Station, Vraj Chowk, Simada Naka, Surat.</li>
                                            <li> ( Ojas Rajai & Kunal rajai )</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> */}
                    <div className="wpo-section-title">
                        {/* <span>Our Wedding</span> */}
                        <h2 style={{ borderBottom: "4px solid burlywood" }}>At Bhavnagar</h2>
                    </div>
                    <div className="wpo-event-wrap">
                        <div className="row">
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1000ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={EngagementImg} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            {/* <h2>Mandap Muhrat</h2> */}
                                            <h2>Engagement</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 29/12/2023 Friday</li>
                                            {/* <li>Afternoon 01:00 P.M.</li> */}
                                            <li>Shree Rajai Panchayat Bhavan, Sardar Nagar, Bhavnagar.</li>
                                            {/* <li>(Urvashi Rajai & Nandini Rajai)</li> */}
                                            <li>
                                                {" "}
                                                <a
                                                    className="popup-gmaps"
                                                    href="https://www.google.com/maps/place/Shree+Rajai+Panchayat+Bhavan/@21.7540422,72.1515117,21z/data=!4m6!3m5!1s0x395f5a7224ad3e27:0xc427c3cc1c9a73a4!8m2!3d21.7540024!4d72.15157!16s%2Fg%2F11h9x2ptwz?authuser=0&hl=en&entry=ttu"
                                                >
                                                    See Location
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1000ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={Haldirasam} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            {/* <h2>Mandap Muhrat</h2> */}
                                            <h2>Haldi Rasam</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 29/12/2023 Friday</li>
                                            <li> Afternoon 01:00 P.M.</li>
                                            <li>Sant Shri Prabharam Hall, Sindhunagar, Bhavnagar</li>
                                            <li>(Urvashi Rajai & Nandini Rajai)</li>
                                            <li>
                                                {" "}
                                                <a
                                                    className="popup-gmaps"
                                                    href="https://www.google.com/maps/place/Shree+Rajai+Panchayat+Bhavan/@21.7540422,72.1515117,21z/data=!4m6!3m5!1s0x395f5a7224ad3e27:0xc427c3cc1c9a73a4!8m2!3d21.7540024!4d72.15157!16s%2Fg%2F11h9x2ptwz?authuser=0&hl=en&entry=ttu"
                                                >
                                                    See Location
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1200ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={PujSaheb} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Puj Bhaherana Saheb</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 29/12/2023 Friday</li>
                                            <li> Evening 04:00 To 06:00 P.M.</li>
                                            {/* <li> <b>( Smt. Shital S. Rajai )</b></li> */}
                                            <li>Puj Rajai Panchayat Bhavan, Sindhunagar, Bhavnagar</li>
                                            <li></li>
                                            <li>
                                                {" "}
                                                <a
                                                    className="popup-gmaps"
                                                    href="https://www.google.com/maps/place/Shree+Rajai+Panchayat+Bhavan/@21.7540422,72.1515117,21z/data=!4m6!3m5!1s0x395f5a7224ad3e27:0xc427c3cc1c9a73a4!8m2!3d21.7540024!4d72.15157!16s%2Fg%2F11h9x2ptwz?authuser=0&hl=en&entry=ttu"
                                                >
                                                    See Location
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1400ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={Sangeet} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Sangeet Sandhya</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 29/12/2023 Friday</li>
                                            <li>Evening 07:00 to 09:30 P.M.</li>
                                            <li>Puj Rajai Panchayat Bhavan, Sindhunagar, Bhavnagar</li>
                                            <li> ( Mahek Rajai & Nandini rajai )</li>
                                            <li>
                                                {" "}
                                                <a
                                                    className="popup-gmaps"
                                                    href="https://www.google.com/maps/place/Shree+Rajai+Panchayat+Bhavan/@21.7540422,72.1515117,21z/data=!4m6!3m5!1s0x395f5a7224ad3e27:0xc427c3cc1c9a73a4!8m2!3d21.7540024!4d72.15157!16s%2Fg%2F11h9x2ptwz?authuser=0&hl=en&entry=ttu"
                                                >
                                                    See Location
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1400ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={Ghadiyun} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Ghadiyun</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 29/12/2023 friday</li>
                                            <li>Night 11:00 P.M.</li>
                                            <li>Puj Rajai Panchayat Bhavan, Sindhunagar, Bhavnagar</li>
                                            <li> ( Priti Rajai & Shital rajai )</li>
                                            <li>
                                                {" "}
                                                <a
                                                    className="popup-gmaps"
                                                    href="https://www.google.com/maps/place/Shree+Rajai+Panchayat+Bhavan/@21.7540422,72.1515117,21z/data=!4m6!3m5!1s0x395f5a7224ad3e27:0xc427c3cc1c9a73a4!8m2!3d21.7540024!4d72.15157!16s%2Fg%2F11h9x2ptwz?authuser=0&hl=en&entry=ttu"
                                                >
                                                    See Location
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1400ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={SaheraBandhi} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Sahera Bandhi</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 30/12/2023 Saturday</li>
                                            <li>Morning 02:00 P.M.</li>
                                            <li>Puj Rajai Panchayat Bhavan, Sindhunagar, Bhavnagar</li>
                                            <li> </li>
                                            <li>
                                                {" "}
                                                <a
                                                    className="popup-gmaps"
                                                    href="https://www.google.com/maps/place/Shree+Rajai+Panchayat+Bhavan/@21.7540422,72.1515117,21z/data=!4m6!3m5!1s0x395f5a7224ad3e27:0xc427c3cc1c9a73a4!8m2!3d21.7540024!4d72.15157!16s%2Fg%2F11h9x2ptwz?authuser=0&hl=en&entry=ttu"
                                                >
                                                    See Location
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1400ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={Barat} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Barat</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 30/12/2023 Saturday</li>
                                            <li>Afternoon 03:00 P.M.</li>
                                            <li>Start from Puj Rajai Panchayat Bhavan to Devu Maa Chowk, Bhavnagar</li>
                                            <li></li>
                                            <li>
                                                {" "}
                                                <a
                                                    className="popup-gmaps"
                                                    href="https://www.google.com/maps/place/Shree+Rajai+Panchayat+Bhavan/@21.7540422,72.1515117,21z/data=!4m6!3m5!1s0x395f5a7224ad3e27:0xc427c3cc1c9a73a4!8m2!3d21.7540024!4d72.15157!16s%2Fg%2F11h9x2ptwz?authuser=0&hl=en&entry=ttu"
                                                >
                                                    See Location
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1400ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={WeddingReception} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>The Wedding Reception</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 30/12/2023 Saturday</li>
                                            <li>Night 08:00 to 10:00 P.M.</li>
                                            <li>R.K.Resort & Restaurant, Vartej, Bhavnagar</li>
                                            <li></li>
                                            <li>
                                                {" "}
                                                <a
                                                    className="popup-gmaps"
                                                    href="https://www.google.com/maps/place/RK+Resort/@21.7426593,72.0672439,21z/data=!4m9!3m8!1s0x395f516a5524ac07:0xa98af78d87c10caa!5m2!4m1!1i2!8m2!3d21.742635!4d72.067322!16s%2Fg%2F11h0g494v?entry=ttu"
                                                >
                                                    See Location
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-event-item  fadeInUp"
                                    data-wow-duration="1400ms"
                                >
                                    <div className="wpo-event-img">
                                        <div className="wpo-event-img-inner">
                                            <img src={Wedlock} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Wed-Lock</h2>
                                        </div>
                                        <ul>
                                            <li>Dt. 30/12/2023 Saturday</li>
                                            <li>Evening 06:25 P.M.</li>
                                            <li>R.K.Resort & Restaurant, Vartej, Bhavnagar</li>
                                            <li></li>
                                            <li>
                                                {" "}
                                                <a
                                                    className="popup-gmaps"
                                                    href="https://www.google.com/maps/place/RK+Resort/@21.7426593,72.0672439,21z/data=!4m9!3m8!1s0x395f516a5524ac07:0xa98af78d87c10caa!5m2!4m1!1i2!8m2!3d21.742635!4d72.067322!16s%2Fg%2F11h0g494v?entry=ttu"
                                                >
                                                    See Location
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end container */}
            </section>
            <section className="wpo-portfolio-section-s2 section-padding" id="gallery">
                <h2 className="hidden">some</h2>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="sortable-gallery">
                                <div className="gallery-filters" />
                                <div className="portfolio-grids gallery-container clearfix">
                                    <div className="grid">
                                        <div className="img-holder">
                                            <a
                                                href={Gallery1}
                                                className="fancybox"
                                            // data-fancybox-group="gall-1"
                                            >
                                                <img
                                                    src={Gallery1}
                                                    alt=""
                                                    className="img img-responsive"
                                                />
                                                <div className="hover-content">
                                                    <i className="ti-plus" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="img-holder">
                                            <a
                                                href={JKImage}
                                                className="fancybox"
                                                data-fancybox-group="gall-1"
                                            >
                                                <img
                                                    src={JKImage}
                                                    alt=""
                                                    className="img img-responsive"
                                                />
                                                <div className="hover-content">
                                                    <i className="ti-plus" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="img-holder">
                                            <a
                                                href={Gallery3}
                                                className="fancybox"
                                                data-fancybox-group="gall-1"
                                            >
                                                <img
                                                    src={Gallery3}
                                                    alt=""
                                                    className="img img-responsive"
                                                />
                                                <div className="hover-content">
                                                    <i className="ti-plus" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="img-holder">
                                            <a
                                                href={Gallery2}
                                                className="fancybox"
                                                data-fancybox-group="gall-1"
                                            >
                                                <img
                                                    src={Gallery2}
                                                    alt=""
                                                    className="img img-responsive"
                                                />
                                                <div className="hover-content">
                                                    <i className="ti-plus" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div className="grid">
                                        <div className="img-holder">
                                            <a
                                                href={Gallery4}
                                                className="fancybox"
                                                data-fancybox-group="gall-1"
                                            >
                                                <img
                                                    src={Gallery4}
                                                    alt=""
                                                    className="img img-responsive"
                                                />
                                                <div className="hover-content">
                                                    <i className="ti-plus" />
                                                </div>
                                            </a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end container */}
            </section>
            <section class="wpo-event-section-s3 section-padding" id="event">
                <div class="container">
                    <div class="wpo-section-title">
                        <h2>When & Where</h2>
                    </div>
                    <div class="wpo-event-wrap">
                        <div class="row">
                            <div class="col col-lg-6 col-md-6 col-12">
                                <div class="wpo-event-item  fadeInUp" data-wow-duration="1000ms">
                                    <div class="wpo-event-img">
                                        <div class="wpo-event-img-inner">
                                            <img src={when1} />
                                        </div>
                                    </div>
                                    <div class="wpo-event-text">
                                        <div class="title">
                                            <h2>Surat: Residence</h2>
                                        </div>
                                        <ul>
                                            <li> 25/A Navdurga Socity,</li>
                                            <li> Beside Maharana Pratap Gardan,</li>
                                            <li>Nana Varachha Main Road,</li>
                                            <li>Surat -395006</li>
                                            <li> <a class="popup-gmaps" href="https://maps.app.goo.gl/SNbZimEvmU87dNYq8">See
                                                Location</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-lg-6 col-md-6 col-12">
                                <div class="wpo-event-item  fadeInUp" data-wow-duration="1200ms">
                                    <div class="wpo-event-img">
                                        <div class="wpo-event-img-inner">
                                            <img src={when2} alt="" />
                                        </div>
                                    </div>
                                    <div class="wpo-event-text">
                                        <div class="title">
                                            <h2>Bhavnagar Wedding Venue</h2>
                                        </div>
                                        <ul>
                                            <li> 08:00 PM – 10:00 PM</li>
                                            <li>R.K.Resort  & Restaurant</li>
                                            <li>Vartej,</li>
                                            <li>Bhavnagar</li>
                                            <li> <a class="popup-gmaps" href="https://www.google.com/maps/place/RK+Resort/@21.7426593,72.0672439,21z/data=!4m9!3m8!1s0x395f516a5524ac07:0xa98af78d87c10caa!5m2!4m1!1i2!8m2!3d21.742635!4d72.067322!16s%2Fg%2F11h0g494v?entry=ttu">See
                                                Location</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* <section className="wpo-contact-section-s4 section-padding" id="rsvp">
                <div
                    className="wpo-contact-section-inner"
                    style={{
                        background: "url(./assets/images/rsvp/bg.jpg) no-repeat center center",
                        backgroundSize: "cover"
                    }}
                >
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col col-xl-5 col-lg-5 col-md-12 col-12">
                                <div
                                    className="wpo-contact-section-wrapper  fadeInLeftSlow"
                                    data-wow-duration="1700ms"
                                >
                                    <div className="wpo-contact-form-area">
                                        <div className="wpo-section-title">
                                            <h2>Are You Attending?</h2>
                                        </div>
                                        <div

                                            className="contact-validation-active"
                                            id="contact-form-main"
                                        >
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Name"
                                                    value={name}
                                                    onChange={(event) => setName(event.target.value)}
                                                />
                                            </div>
                                            <div className="radio-buttons">
                                                <p>
                                                    <input
                                                        type="radio"
                                                        id="attend"
                                                        name='yes'
                                                        checked={attend == true ? true : false}
                                                        onChange={() => { setAttend(true) }}
                                                    // defaultChecked = "true"
                                                    />
                                                    <label htmlFor="attend">Yes, I will be there</label>
                                                </p>
                                                <p>
                                                    <input type="radio" id="not" checked={attend == false ? true : null}
                                                        name='yes'
                                                        onChange={() => { setAttend(false) }}
                                                    />
                                                    <label htmlFor="not">Sorry, I can’t come</label>
                                                </p>
                                            </div>
                                            <div>
                                                <span className="option-dropdown">Number Of Guests :</span>
                                                <select className="form-control" value={totalGuest} disabled={attend == false} onChange={(event) => setTotalGuest(event.target.value)}>

                                                    <option value="0">00</option>
                                                    <option value="1">01</option>
                                                    <option value="2">02</option>
                                                    <option value="3">03</option>
                                                    <option value="4">04</option>
                                                    <option value="5">05</option>
                                                    <option value="6">06</option>
                                                    <option value="7">07</option>
                                                    <option value="8">08</option>
                                                    <option value="9">09</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                </select>
                                            </div>
                                            <div>
                                                <span className="option-dropdown">Meal Preferences :</span>
                                                <select className="form-control" value={meal} disabled={attend == false} onChange={(event) => setMeal(event.target.value)}>
                                                    <option value="VEG">Veg.</option>
                                                    <option value="Jain">Jain</option>
                                                </select>
                                               
                                            </div>
                                            <div className="submit-area">
                                                <button type="submit" className="theme-btn" onClick={submitDetails}>
                                                    RSVP
                                                </button>
                                                <div id="c-loader">
                                                    <i className="ti-reload" />
                                                </div>
                                            </div>
                                            <div className="clearfix error-handling-messages">
                                                <div id="success">Thank you</div>
                                                <div id="error">
                                                    {" "}
                                                    Error occurred while sending email. Please try again
                                                    later.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className="wpo-blog-section section-bg section-padding">
                <div className="container">
                    <div className="row">
                        <div className="wpo-section-title">
                            <span>Our Blog</span>
                            <h2>Our Latest Wedding News</h2>
                        </div>
                    </div>
                    <div className="wpo-blog-items">
                        <div className="row">
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-blog-item  fadeInUp"
                                    data-wow-duration="1000ms"
                                >
                                    <div className="wpo-blog-img">
                                        <img src="assets/images/blog/img-1.jpg" alt="" />
                                    </div>
                                    <div className="wpo-blog-content">
                                        <ul>
                                            <li>
                                                By <a href="blog-single.html">Robertson Doe</a>
                                            </li>
                                            <li>25 Sep 2023</li>
                                        </ul>
                                        <h2>
                                            <a href="blog.html">Top 10 wedding bouquet arranging idea</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-blog-item  fadeInUp"
                                    data-wow-duration="1200ms"
                                >
                                    <div className="wpo-blog-img">
                                        <img src="assets/images/blog/img-2.jpg" alt="" />
                                    </div>
                                    <div className="wpo-blog-content">
                                        <ul>
                                            <li>
                                                By <a href="blog-single.html">David Luis</a>
                                            </li>
                                            <li>23 Sep 2023</li>
                                        </ul>
                                        <h2>
                                            <a href="blog.html">You must need a great photographer.</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div
                                    className="wpo-blog-item  fadeInUp"
                                    data-wow-duration="1400ms"
                                >
                                    <div className="wpo-blog-img">
                                        <img src="assets/images/blog/img-3.jpg" alt="" />
                                    </div>
                                    <div className="wpo-blog-content">
                                        <ul>
                                            <li>
                                                By <a href="blog-single.html">Aliana de</a>
                                            </li>
                                            <li>21 Sep 2023</li>
                                        </ul>
                                        <h2>
                                            <a href="blog.html">
                                                Make sure your wedding gown is the best one.
                                            </a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="b-shape-2">
                            <img src="assets/images/blog/shape-2.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div className="b-shape">
                    <img src="assets/images/blog/shape-1.svg" alt="" />
                </div>
            </section> */}
            <footer className="wpo-site-footer-s2" style={{ marginTop: "4%" }}>
                <div className="wpo-upper-footer">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="widget link-widget">
                                    <div className="widget-title ">
                                        <h3>Links</h3>
                                    </div>
                                    <div className="link-wrap">
                                        <ul>
                                            <li>
                                                <a href="#gallery">Gallery</a>
                                            </li>
                                            <li>
                                                <a onClick={handleOthers}>Others</a>
                                            </li>
                                            <SpecialGuestName
                                                show={specialguestShow}
                                                onHide={() => setSpecialGuestShow(false)}
                                                name={name}
                                            />
                                        </ul>
                                        {/* <ul>
                                            <li>
                                                <a href="#rsvp">RSVP</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="widget about-widget">
                                    <div className="logo widget-title">
                                        <a className="logo" href="index.html">
                                            KinDeep
                                        </a>
                                    </div>
                                    <p>
                                        We can’t wait to see all of our beloved friends and relatives
                                        at our wedding.
                                    </p>
                                    {/* <ul>
                                        <li>
                                            <a href="#">
                                                <i className="ti-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="ti-twitter-alt" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="ti-instagram" />
                                            </a>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="widget wpo-service-link-widget">
                                    <div className="widget-title">
                                        <h3>Contact </h3>
                                    </div>
                                    <div className="contact-ft" style={{ height: "82px" }}>
                                        <p>MO. - 99040 16789</p>
                                        <p>MO. - 97231 30130</p>
                                        {/* <p>25/A, Navdurga Society, B/S Maharana Paratap Garden, Nana Varacha, Surat.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className="row align-items-center d-flex justify-content-center">
                            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="widget wpo-service-link-widget">
                                    <div className="widget-title">
                                        <h3>Address </h3>
                                    </div>
                                    <div className="contact-ft" style={{ height: "82px" }}>
                                        <p>25/A, Navdurga Society, B/S Maharana Paratap Garden, Nana Varacha, Surat.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wpo-lower-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col col-xs-12">
                                <p className="copyright">
                                    {" "}
                                    ©Copyright 2023-24 | <a href="http://www.i-tax.in/">Taxfile Invosoft PVT LTD.</a> | All right
                                    reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="ft-shape-1">
                    <img src="assets/images/footer-shape-1.svg" alt="" />
                </div>
                <div className="ft-shape-2">
                    <img src="assets/images/footer-shape-2.svg" alt="" />
                </div> */}
            </footer>
        </div>
    )
}

export default Home