import React, { useEffect, useState } from 'react'
import "../App.css"

const Countdown = () => {

    const [countdownDate, setCountdownDate] = useState(new Date('12/30/2023').getTime());
    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        setInterval(() => setNewTime(), 1000);
    }, []);

    const setNewTime = () => {
        if (countdownDate) {
            const currentTime = new Date().getTime();

            const distanceToDate = countdownDate - currentTime;

            let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
            let hours = Math.floor(
                (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
            );
            let minutes = Math.floor(
                (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
            );
            let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

            const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

            days = `${days}`;
            if (numbersToAddZeroTo.includes(hours)) {
                hours = `0${hours}`;
            } else if (numbersToAddZeroTo.includes(minutes)) {
                minutes = `0${minutes}`;
            } else if (numbersToAddZeroTo.includes(seconds)) {
                seconds = `0${seconds}`;
            }

            setState({ days: days, hours: hours, minutes, seconds });
        }
    };

    return (
        <div>
            {/* <header className="header">
                <h1 className="title">React Hooks Countdown</h1>
            </header>
            <h3 className="subtitle">A simple countdown to demonstrate, well, a countdown using the useEffect() and useState() hooks in React.</h3> */}
            <div className='countdown-wrapper d-flex center_text'>
                
                <div className='time-section'>
                    <div className='time text-white fs-1 ms-5'>{state.days || '0'}</div>
                    <h2 className="time-text text-white fs-1 ms-5 fontS"> Days </h2>
                </div>
                {/* <div className='time-section'>
                    <div className='time text-white fs-1'>:</div>
                </div> */}
                <div className='time-section'>
                    <div className='time text-white fs-1 ms-5'>{state.hours || '00'}</div>
                    <h2 className="time-text text-white fs-1 ms-5 fontS"> Hours </h2>
                </div>
                {/* <div className='time-section'>
                    <div className='time text-white fs-1'>:</div>
                </div> */}
                <div className='time-section'>
                    <div className='time text-white fs-1 ms-5'>{state.minutes || '00'}</div>
                    <h2 className="time-text text-white fs-1 ms-5 fontS">Min</h2>
                </div>
                {/* <div className='time-section'>
                    <div className='time text-white fs-1'>:</div>
                </div> */}
                <div className='time-section none-media'>
                    <div className='time text-white fs-1 ms-5'>{state.seconds || '00'}</div>
                    <h2 className="time-text text-white fs-1 ms-5 fontS">Sec</h2>
                </div>
            </div>
        </div>
    )
}

export default Countdown