import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import axios from 'axios'
import { BsPrinterFill } from "react-icons/bs";
import { notification,Table } from 'antd';


const GetList = () => {
    const [getcontactdata, setGetContactData] = useState([])
    const [searchguest, setSearchGuest] = useState("")
    const [totalNoofguest, setTotalNoofguest] = useState(0);
    const [tableParams, setTableParams] = useState({
      pagination: {
          current: 1,
          pageSize: 20,
          position: ['bottomCenter']
      },
  });
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
        pagination,
        filters,
        ...sorter,
    });
}
    const GetContactList = async () => {
        try {
            const res = await axios.get('http://www.lohanasummit.i-tax.in/api/PosterApi/GuestList')
            setGetContactData(res.data)
        } catch (error) {
            console.log(error,)
        }
    }
    useEffect(() => {
        GetContactList()
    }, [])

    const filteredData = getcontactdata.filter((item) => {
        const searchTermLowerCase = searchguest;
        return (
            item.Name.toLowerCase().includes(searchTermLowerCase) ||
            (item.Attend ? "Yes" : "No").toLowerCase().includes(searchTermLowerCase)
        );
    });
    useEffect(() => {
        const total = filteredData.reduce((total, row) => total + parseFloat(row.Noofguest || 0), 0)
        setTotalNoofguest(total)
    }, [filteredData])
    const handlePrint = () => {
        const printContent = `
          <html>
            <head>
              <title>Print</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                }
                table {
                  border-collapse: collapse;
                  width: 100%;
                }
                th, td {
                  border: 1px solid black;
                  padding: 8px;
                  text-align: left;
                }
                th {
                  background-color: #f2f2f2;
                }
              </style>
            </head>
            <body>
              <h5>Guest List</h5>
              <table>
                <thead>
                  <tr>
                  <th>Guest Name</th>
                  <th>Meal</th>
                  <th>No of Guest</th>
                  <th>Attend</th>
                  </tr>
                </thead>
                <tbody>
                  ${getcontactdata.map(item => `
                    <tr>
                      <td>${item.Name}</td>
                      <td>${item.Meal}</td>
                      <td>${item.Noofguest}</td>
                      <td>${item.Attend == true ? "Yes" : "No"}</td>
                    </tr>
                  `).join('')}
                </tbody>
              </table>
            </body>
          </html>
        `;
        const printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write(printContent);
        printWindow.document.close();

        // Trigger print after the content is loaded in the new tab
        printWindow.print();
    };
    const column = [
      {
        title:'Guest Name',
        dataIndex:'Name'
      },
      {
        title:'Meal',
        dataIndex:'Meal'
      },
      {
        title:'No. Of Guest',
        dataIndex:'Noofguest'
      },
      {
        title:'Attend',
        render:((record)=>record.Attend == true ? "Yes" : "No")
      }
    ]
    const RecordFooter = () => (
      <div>
          <h5><b>Total Records: </b>{totalNoofguest}</h5>
      </div>
  );
    return (
        <div>
            <div className='content-wrapper'>
                <div className='admin-gust-name-section-start'>
                    <div className='heading-searching-main'>
                        <div className='guis-list-headng'>
                            <h5>Guest List</h5>
                        </div>
                        <div className='guis-list-search d-flex'>
                            <div className='print-function' onClick={handlePrint}>
                                <BsPrinterFill size={20} />
                            </div>
                            <input type="text" placeholder='Search Guest Name' onChange={(event) => { setSearchGuest(event.target.value) }} />
                        </div>
                    </div>
                    {/* <div className="table-responsive">
                        <table id="dataTableExample1" className="table table-bordered table-striped table-hover">
                            <thead className="back_table_color">
                                <tr className=" back-color info">
                                    <th>#</th>
                                    <th>Guest Name</th>
                                    <th>Meal</th>
                                    <th>No of Guest</th>
                                    <th>Attend</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((item, index) => (
                                    <tr key={index} className='align_middle'>
                                        <td className='data-index'>{index + 1}</td>
                                        <td>{item.Name}</td>
                                        <td>{item.Meal}</td>
                                        <td>{item.Noofguest}</td>
                                        <td>{item.Attend == true ? "Yes" : "No"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> */}
                    <Table columns={column} dataSource={filteredData} pagination={tableParams.pagination} onChange={handleTableChange} footer={RecordFooter}/>
                </div>

            </div>

        </div>
    )
}
export default GetList