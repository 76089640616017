import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from './Header';
// import Footer from './Footer';
import Home from './Home';
import SpecialGuestForm from './SpecialGuestForm';
const UserAdminMain = () => {
    return (
        <div>
            <Router>
                <Header />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route  path="/specialguest">
                        <SpecialGuestForm />
                    </Route>
                </Switch>
                {/* <Footer /> */}
            </Router>
        </div>
    )
}

export default UserAdminMain