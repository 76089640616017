import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
const Header = () => {
    const location = useLocation()
    return (
        <div>
            <div>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#">
                                <i className="fas fa-bars" />
                            </a>
                        </li>
                    </ul>
                </nav>
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    {/* Brand Logo */}
                    <a href="#" className="brand-link" >
                        {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} /> */}
                        {/* <span className="brand-text font-weight-light"><img src={JK} /></span> */}
                        <span className="brand-text font-weight-light">𝓚𝓲𝓷𝓭𝓮𝓮𝓹</span>
                    </a>
                    {/* Sidebar */}
                    <div className="sidebar">
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className={location.pathname == '/admin' ? 'nav-item active-admin-link' : 'nav-item'}>
                                    <Link to="/admin" className="nav-link ">
                                        <i className="nav-icon fas fa-th" />
                                        <p className='admin-list'>
                                            Guest List
                                        </p>
                                    </Link>
                                </li>
                                {/* <li className={location.pathname == '/specialguestrecord' ? 'nav-item active-admin-link' : 'nav-item'}>
                                    <Link to="/specialguestrecord" className="nav-link ">
                                        <i className="nav-icon fas fa-th" />
                                        <p className='admin-list'>
                                            Special Guest List
                                        </p>
                                    </Link>
                                </li> */}
                                <li className={location.pathname == '/importdata' ? 'nav-item active-admin-link' : 'nav-item'}>
                                    <Link to="/importdata" className="nav-link ">
                                        <i className="nav-icon fas fa-th" />
                                        <p className='admin-list'>ImportData</p>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
            </div>
        </div>
    )
}

export default Header