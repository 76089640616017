import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Select from 'react-select'

const ImportForm = () => {

  const [name, setName] = useState("")
  const [mobile1, setMobile1] = useState("")
  const [mobile2, setMobile2] = useState("")
  const [mobile3, setMobile3] = useState("")
  const [mobile4, setMobile4] = useState("")
  const [category, setCategory] = useState("")
  const [gropname, setGroupName] = useState("")
  // const []
  const CategoryOption = [
    { value: 'RECEPTION', label: 'RECEPTION' },
    { value: 'CHITTHO', label: 'CHITTHO' },
    { value: 'K.K', label: 'K.K' },
    { value: 'KANKOTRI', label: 'KANKOTRI' },
  ];
  const GroupName = [
    { value: 'BIG B', label: 'BIG B' },
    { value: 'FAMILY', label: 'FAMILY' },
    { value: 'JD FRIEND', label: 'JD FRIEND' },
    { value: 'JV SENA', label: 'JV SENA' },
    { value: 'LBS', label: 'LBS' },
    { value: 'MOHIT FRD', label: 'MOHIT FRD' },
    { value: 'NAVDURGA', label: 'NAVDURGA' },
    { value: 'SURAT STAFF', label: 'SURAT STAFF' },
    { value: 'WPA FRIEND', label: 'WPA FRIEND' },
  ];
  return (
    <div>
      <div className='import-data-form-main'>
        <Row>
          <Col lg={12}>
            <div className='input-field-section-main'>
              <label>Name : </label>
              <div className='input-fuild'>
                <input type='text' className='w-100' value={name}
                  placeholder="Enter Name"
                  onChange={(event) => { setName(event.target.value) }} />
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className='input-field-section-main'>
              <label>Mobile1 : </label>
              <div className='input-fuild'>

                <input type='text' value={mobile1} className='w-100'
                  placeholder="Enter Mobile"
                  onChange={(event) => {
                    const input = event.target.value;
                    const numericInput = input.replace(/\D/g, '');
                    const limitedInput = numericInput.slice(0, 20);
                    setMobile1(limitedInput)
                  }} />
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className='input-field-section-main'>
              <label>Mobile2 : </label>
              <div className='input-fuild'>
                <input type='text' className='w-100'
                  placeholder='Enter Mobile'
                  value={mobile2} onChange={(event) => {
                    const input = event.target.value;
                    const numericInput = input.replace(/\D/g, '');
                    const limitedInput = numericInput.slice(0, 20);
                    setMobile2(limitedInput)
                  }} />
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className='input-field-section-main'>
              <label>Mobile3 : </label>
              <div className='input-fuild'>
                <input type='text' className='w-100'
                  placeholder='Enter Mobile'
                  value={mobile3} onChange={(event) => {
                    const input = event.target.value;
                    const numericInput = input.replace(/\D/g, '');
                    const limitedInput = numericInput.slice(0, 20);
                    setMobile3(limitedInput)
                  }} />
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className='input-field-section-main'>
              <label>Mobile4 : </label>
              <div className='input-fuild'>
                <input type='text' className='w-100'
                  placeholder='Enter Mobile'
                  value={mobile4} onChange={(event) => {
                    const input = event.target.value;
                    const numericInput = input.replace(/\D/g, '');
                    const limitedInput = numericInput.slice(0, 20);
                    setMobile4(limitedInput)
                  }} />
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className='input-field-section-main'>
              <label>Category : </label>
              <div className='input-fuild'>
                <Select
                  className='w-100'
                  options={CategoryOption}
                  value={CategoryOption.find((option) => option.value == category)}
                  onChange={(selected) => {
                    setCategory(selected.value)
                  }}
                  placeholder="Select Category"

                />
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className='input-field-section-main'>
              <label>Group Name : </label>
              <div className='input-fuild'>
                <Select
                  className='w-100'
                  options={GroupName}
                  value={GroupName.find((option) => option.value == gropname)}
                  onChange={(selected) => {
                    setGroupName(selected.value)
                  }}
                  placeholder="Select Group"

                />
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className='input-field-section-main'>
              <div className="function-checkbox">
                <label><input type="checkbox" /><span>Reseption</span></label>
              </div>
            </div>
          </Col>
        </Row>

      </div>
    </div>
  )
}

export default ImportForm