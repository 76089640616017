import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <header id="header">
            <div className="wpo-site-header wpo-header-style-3">
                <nav className="navigation navbar navbar-expand-lg navbar-light p-3">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            {/* <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <button type="button" className="navbar-toggler open-btn">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar first-angle" />
                                        <span className="icon-bar middle-angle" />
                                        <span className="icon-bar last-angle" />
                                    </button>
                                </div>
                            </div> */}
                            <div className="col-lg-2 col-md-6 col-6">
                                <div className="navbar-header">
                                    <Link className="navbar-brand" to="/" style={{ color: "#657150" }}>
                                        kinDeep
                                    </Link>
                                </div>
                            </div>
                            <div>
                                
                            </div>
                        </div>
                    </div>
                    {/* end of container */}
                </nav>
            </div>
        </header>
    )
}

export default Header