import React from 'react'
import Header from './Header'
import Footer from './Footer'
import './style/style.css'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import GetList from './GetList';
import GetSpecialGuest from './GetSpecialGuest';
import ImportMain from './ImportMain'
const AdminMain = () => {
    return (
        <div>
            <Router>
                <Header />
                <Switch>
                    <Route path="/admin">
                        <GetList />
                    </Route>
                    <Route path="/specialguestrecord">
                        <GetSpecialGuest />
                    </Route>
                    <Route path="/importdata">
                        <ImportMain />
                    </Route>
                </Switch>
                <Footer />
            </Router>
        </div>
    )
}

export default AdminMain