import React from 'react'

const Footer = () => {
    return (
        <div>
            <div>
                <footer className="main-footer text-center ">
                    <strong>Copyright © 2023 TaxfilePoster.</strong>
                    All rights reserved.
                </footer>
            </div>
        </div>
    )
}

export default Footer