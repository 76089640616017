import React from 'react';
import ImporttableData from './Importtable';
import { Button } from 'react-bootstrap'
import { GoPlus } from "react-icons/go";
import Modal from 'react-bootstrap/Modal';
import ImportForm from './ImportForm';
function ImportNewForm(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Data
                </Modal.Title>
            </Modal.Header>
            <ImportForm />
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

const ImportMain = () => {
    const [datashow, setDataShow] = React.useState(false);
    return (
        <div>
            <div className='content-wrapper'>
                <div className='add-data-main-section'>
                    <Button className='add-data-btn' onClick={() => setDataShow(true)}><GoPlus />Add Data</Button>
                    <ImportNewForm
                        show={datashow}
                        onHide={() => setDataShow(false)}
                    />
                </div>
            </div>
        </div>
    )
}

export default ImportMain