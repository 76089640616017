import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { notification } from 'antd';


const SpecialGuestForm = () => {
    const [name, setName] = useState("")
    const [attend, setAttend] = useState(true)
    const [totalGuest, setTotalGuest] = useState("0")
    const [meal, setMeal] = useState("Chicken")
    const [drinks, setDrinks] = useState("Whiskey")

    const submitDetails = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.post('http://www.lohanasummit.i-tax.in/api/PosterApi/CreateRSVPSpecial',
                {
                    Name: name,
                    Attend: attend,
                    Noofguest: totalGuest,
                    Meal: meal,
                    Drinks: drinks
                })
            if (res.data.Success == true) {
                setName("")
                setAttend(true)
                setTotalGuest("0")
                setDrinks("Whiskey")
                setMeal("Chicken")
                notification.success({
                    message: 'Thank you !!!',
                    placement: 'bottomRight', // You can adjust the placement
                    duration: 1, // Adjust the duration as needed
                });
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    return (
        <div>
            <section className="wpo-contact-section-s4 section-padding" id="rsvp">
                <div
                    className="wpo-contact-section-inner"
                    style={{
                        background: "url(./assets/images/rsvp/bg.jpg) no-repeat center center",
                        backgroundSize: "cover"
                    }}
                >
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col col-xl-5 col-lg-5 col-md-12 col-12">
                                <div
                                    className="wpo-contact-section-wrapper "
                                    data-wow-duration="1700ms"
                                >
                                    <div className="wpo-contact-form-area">
                                        <div className="wpo-section-title">
                                            <h2>Are You Attending?</h2>
                                        </div>
                                        <div

                                            className="contact-validation-active"
                                            id="contact-form-main"
                                        >
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Name"
                                                    value={name}
                                                    onChange={(event) => setName(event.target.value)}
                                                />
                                            </div>
                                            <div className="radio-buttons">
                                                <p>
                                                    <input
                                                        type="radio"
                                                        id="attend"
                                                        name='yes'
                                                        checked={attend == true ? true : false}
                                                        onChange={() => { setAttend(true) }}
                                                    // defaultChecked = "true"
                                                    />
                                                    <label htmlFor="attend">Yes, I will be there</label>
                                                </p>
                                                <p>
                                                    <input type="radio" id="not" checked={attend == false ? true : null}
                                                        name='yes'
                                                        onChange={() => { setAttend(false) }}
                                                    />
                                                    <label htmlFor="not">Sorry, I can’t come</label>
                                                </p>
                                            </div>
                                            <div>
                                                <span className="option-dropdown">Number Of Guests :</span>
                                                <select className="form-control" value={totalGuest} disabled={attend == false} onChange={(event) => setTotalGuest(event.target.value)}>
                                                    <option value="0">00</option>
                                                    <option value="1">01</option>
                                                    <option value="2">02</option>
                                                    <option value="3">03</option>
                                                    <option value="4">04</option>
                                                    <option value="5">05</option>
                                                    <option value="6">06</option>
                                                    <option value="7">07</option>
                                                    <option value="8">08</option>
                                                    <option value="9">09</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                </select>
                                            </div>
                                            <div>
                                                <span className="option-dropdown">    Meal Preferences :</span>
                                                <select className="form-control" value={meal} disabled={attend == false} onChange={(event) => setMeal(event.target.value)}>
                                                    <option value="Chicken">Chicken</option>
                                                    <option value="Eggs">Eggs</option>
                                                </select>
                                            </div>
                                            <div>
                                                <span className="option-dropdown">Drinks :</span>
                                                <select className="form-control" value={drinks} disabled={attend == false} onChange={(event) => setDrinks(event.target.value)}>
                                                    <option value="Whiskey">Whiskey</option>
                                                    <option value="Vodka">Vodka</option>
                                                    {/* <option value="Rum">Rum</option>
                                                    <option value="Beer">Beer</option> */}
                                                    <option value="Beer">Other</option>
                                                </select>
                                            </div>
                                            <div className="submit-area">
                                                <button type="submit" className="theme-btn" onClick={submitDetails}>
                                                    RSVP
                                                </button>
                                                <div id="c-loader">
                                                    <i className="ti-reload" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SpecialGuestForm