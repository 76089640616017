import React, { useState } from "react";
import Countdown from "./components/Countdown";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import UserAdminMain from "./components/UserAdminMain";
import AdminMain from "./admin/AdminMain";
function App() {
  const [name, setName] = useState("")
  const [totalGuest, setTotalGuest] = useState("")
  const [meal, setMeal] = useState("")
  return (
    // <div>
    <div className="page-wrapper">
      {/* start preloader */}
      {/* <div className="preloader">
        <div>
          <div className="content">
          <div className="loader-circle" />
          <div className="loader-line-mask">
          <div className="loader-line" />
          </div>
          <img src="assets/images/preloader.gif" alt="" />
          </div>
        </div>
        </div>  */}
      <Router>
        <Switch>
          <Route exact path="/">
            <UserAdminMain />
          </Route>
          <Route path="/specialguest">
            <UserAdminMain />
          </Route>
          <Route path="/admin">
            <AdminMain />
          </Route>
          <Route path="/specialguestrecord">
            <AdminMain />
          </Route>
          <Route path="/importdata">
            <AdminMain />
          </Route>
        </Switch>
      </Router>
    </div>

  );
}

export default App;
