import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { BsPrinterFill } from "react-icons/bs";

const GetSpecialGuest = () => {
    const [getcontactdata, setGetContactData] = useState([])
    const [searchguest, setSearchGuest] = useState("")
    const [totalNoofguest, setTotalNoofguest] = useState(0);
    const GetContactList = async () => {
        try {
            const res = await axios.get('http://www.lohanasummit.i-tax.in/api/PosterApi/SpecialGuestList')
            setGetContactData(res.data)
        } catch (error) {
            console.log(error,)
        }
    }
    useEffect(() => {
        GetContactList()
    }, [])

    const filteredData = getcontactdata.filter((item) => {
        const searchTermLowerCase = searchguest;
        return (
            item.Name.toLowerCase().includes(searchTermLowerCase) ||
            (item.Attend ? "Yes" : "No").toLowerCase().includes(searchTermLowerCase)
        );
    });
    useEffect(() => {
        const total = filteredData.reduce((total, row) => total + parseFloat(row.Noofguest || 0), 0)
        setTotalNoofguest(total)
    }, [filteredData])

    const handlePrint = () => {
        const printContent = `
          <html>
            <head>
              <title>Print</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                }
                table {
                  border-collapse: collapse;
                  width: 100%;
                }
                th, td {
                  border: 1px solid black;
                  padding: 8px;
                  text-align: left;
                }
                th {
                  background-color: #f2f2f2;
                }
              </style>
            </head>
            <body>
              <h5>SpecialGuest List</h5>
              <table>
                <thead>
                  <tr>
                  <th>Guest Name</th>
                  <th>Meal</th>
                  <th>Drinks</th>
                  <th>No of Guest</th>
                  <th>Attend</th>
                  </tr>
                </thead>
                <tbody>
                  ${getcontactdata.map(item => `
                    <tr>
                      <td>${item.Name}</td>
                      <td>${item.Meal}</td>
                      <td>${item.Drinks}</td>
                      <td>${item.Noofguest}</td>
                      <td>${item.Attend == true ? "Yes" : "No"}</td>
                    </tr>
                  `).join('')}
                </tbody>
              </table>
            </body>
          </html>
        `;
        const printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write(printContent);
        printWindow.document.close();

        // Trigger print after the content is loaded in the new tab
        printWindow.print();
    };
    return (
        <div>
            <div>
                <div className='content-wrapper'>
                    <div className='admin-gust-name-section-start'>
                        <div className='heading-searching-main'>
                            <div className='guis-list-headng'>
                                <h5>Guest List</h5>
                            </div>
                            <div className='guis-list-search d-flex'>
                                <div className='print-function' onClick={handlePrint}>
                                    <BsPrinterFill size={20} />
                                </div>
                                <input type="text" placeholder='Search Guest Name' onChange={(event) => { setSearchGuest(event.target.value) }} />

                            </div>
                        </div>
                        <div className="table-responsive">
                            <table id="dataTableExample1" className="table table-bordered table-striped table-hover">
                                <thead className="back_table_color">
                                    <tr className=" back-color info">
                                        <th>#</th>
                                        <th>Guest Name</th>
                                        <th>Meal</th>
                                        <th>Drinks</th>
                                        <th>No of Guest</th>
                                        <th>Attend</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((item, index) => (
                                        <tr key={index} className='align_middle'>
                                            <td className='data-index'>{index + 1}</td>
                                            <td>{item.Name}</td>
                                            <td>{item.Meal}</td>
                                            <td>{item.Drinks}</td>
                                            <td>{item.Noofguest}</td>
                                            <td>{item.Attend == true ? "Yes" : "No"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='total-noofguest'>
                            <p>Total Guest: {totalNoofguest}</p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default GetSpecialGuest